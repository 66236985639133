import find from '@ravnur/nanoutils/find';
import head from '@ravnur/nanoutils/head';
import { keyById } from '@ravnur/nanoutils/keyBy';
import sortBy from '@ravnur/nanoutils/sortBy';
import { defineStore } from 'pinia';

import { RavnurApplication } from '@/types/Auth';
import { DEFAULT_PERMISSIONS, Permissions, Permissions$Boolean } from '@/types/Permissions';
import { PortalSettings } from '@/types/Settings';
import { User$Details } from '@/types/User';
import { ApplicationTypes } from '@ravnur/shared/types/Application';

type State = {
  isAuth: boolean;
  user: Nullable<User$Details>;
  applications: RavnurApplication[];
  publicDomain: string;
  permissions: Permissions;
  settings: PortalSettings | null;
};

export const isCurrentApplication = (app: RavnurApplication) => {
  if (!app.adminUrl) {
    return false;
  }
  const urlAdminPathname = new URL(app.adminUrl)?.pathname ?? '';
  return urlAdminPathname.replace(/\//g, '') === location.pathname.replace(/\//g, '');
};
const findCurrentApplication = find(isCurrentApplication);
const findRootApplication = find((app: RavnurApplication) => app.isRoot);
const tenantsOnly = (applications: RavnurApplication[]) =>
  sortBy(
    (a) => a.name,
    applications.filter((a) => !a.isRoot)
  );

const useSecurityStore = defineStore({
  id: 'security',
  state: (): State => ({
    isAuth: false,
    user: null,
    applications: [],
    publicDomain: '/',
    permissions: { ...DEFAULT_PERMISSIONS },
    settings: null,
  }),
  getters: {
    isEmailDomainManagement: (state) => state.settings?.isEmailDomainManagement || false,

    currentApplication: (state) => findCurrentApplication(state.applications),
    defaultApplication: (state) => detectDefaultSite(state.user, state.applications),
    defaultTenant: (state) => detectDefaultSite(state.user, tenantsOnly(state.applications)),
    rootApplication: (state) => findRootApplication(state.applications),
    applicationsAsCache: (state) => keyById(state.applications),
    tenants: (state) => tenantsOnly(state.applications),
    isRootApplication: (state) => findCurrentApplication(state.applications)?.isRoot || false,
    isPublicApplication: (state) =>
      findCurrentApplication(state.applications)?.applicationType === ApplicationTypes.PUBLIC,

    isMetadataAvailable: (state) => state.settings?.isMetadata || false,

    isRolesAvailable(): boolean {
      return this.isManageRolesAllowed || false;
    },
    isUsersAvailable(): boolean {
      return this.isManageUsersAllowed;
    },
    isVideosAvailable(): boolean {
      return (this.isManageVideosAllowed && this.isManageVideosEnabled) || false;
    },
    isAudiosAvailable(): boolean {
      return (this.isManageAudiosAllowed && this.isManageAudiosEnabled) || false;
    },
    isPlaylistsAvailable(): boolean {
      return (this.isManagePlaylistsAllowed && this.isManagePlaylistsEnabled) || false;
    },
    isCategoriesAvailable(): boolean {
      return (this.isManageCategoriesAllowed && this.isManageCategoriesEnabled) || false;
    },
    isGroupsAvailable(): boolean {
      return (this.isManageGroupsAllowed && this.isManageGroupsEnabled) || false;
    },
    isTagsAvailable(): boolean {
      return this.isManageTagsAllowed;
    },

    isDocumentsAvailable: (state) =>
      (hasPermission(state.permissions, 'contentAdmin') && state.settings?.isDocuments) || false,

    isRelatedMediaAvailable: (state) =>
      (hasPermission(state.permissions, 'contentAdmin') && state.settings?.isSmRelated) || false,
    isSettingAvailable: () => false,
    isToolsAvailable: () => false,

    // TODO: set to false by default when BE flag will be added
    isLiveQAAvailable: (state) => state.settings?.isLiveQAAvailable || true,

    isLivePlaylistsEnabled: (state) => state.settings?.isLivePlaylistsEnabled || false,

    isAutoCaptionsAvailable: (state) => !!state.settings?.isAutoCaptions,

    isEnableCcAutoTranslationsAvailable: (state) => {
      const isRootApplication = findCurrentApplication(state.applications)?.isRoot || false;

      return (
        (!!state.settings?.isEnableCcAutoTranslations || isRootApplication) &&
        hasPermission(state.permissions, 'manageSettings')
      );
    },

    isTermSandConditionsAvailable: (state) => !!state.settings?.isTermSandConditions,
    isPrivacyPolicyAvailable: (state) => !!state.settings?.isPrivacyPolicy,
    isContactInfoAvailable: (state) => !!state.settings?.isContactInfo,
    isKnowledgeBase: (state) => !!state.settings?.isFaq,

    // FIXME: we may use different permission for edit functionality
    canEditMetadata: (state) => state.settings?.isMetadata || false,

    isLiveStreamsAvailable: (state) => state.settings?.isLiveStreaming || false,

    // SETTINGS
    isExpirationPolicyEnabled: (state) => !!state.settings?.isExpirationPolicyEnabled,
    isUserCreationEnabled: (state) => !!state.settings?.isUserCreationEnabled,
    isManageVideosEnabled: (state) => !!state.settings?.isVideos,
    isManageAudiosEnabled: (state) => !!state.settings?.isAudios,
    isManagePlaylistsEnabled: (state) => !!state.settings?.isCollections,
    isManageAbuseReportsEnabled: (state) => !!state.settings?.isAbuseReportEnabled,
    isManageFeaturedVideosEnabled: (state) => !!state.settings?.isFeaturedVideosEnabled,
    isManageFeaturedCarouselsEnabled: (state) => !!state.settings?.isFeaturedCarouselsEnabled,
    isManageTagsEnabled: (state) => !!state.settings?.isTagsEnabled,
    isManageCategoriesEnabled(state) {
      const isRootApplication = findCurrentApplication(state.applications)?.isRoot || false;
      return !isRootApplication && !!state.settings?.isCategories;
    },
    isManageGroupsEnabled(state) {
      const isRootApplication = findCurrentApplication(state.applications)?.isRoot || false;
      return !isRootApplication && !!state.settings?.isGroups;
    },
    isAIGenerateDescriptionEnabled: (state) => !!state.settings?.isAiDescriptionGeneration || false,
    isAiGenerationChaptersEnabled: (state) => !!state.settings?.isOpenAiChaptersGeneration || false,
    isMuxEnabled: (state) => !!state.settings?.isMux,

    // PERMISSIONS
    isPurgeUsersAllowed: (state) => hasPermission(state.permissions, 'purgeUsers'),
    isPurgeItemsAllowed: (state) => hasPermission(state.permissions, 'purgeItems'),
    isManageVideosAllowed: (state) => hasPermission(state.permissions, 'contentAdmin'),
    isManageAudiosAllowed: (state) => hasPermission(state.permissions, 'contentAdmin'),
    isManagePlaylistsAllowed: (state) => hasPermission(state.permissions, 'contentAdmin'),
    isManageAbuseReportsAllowed: (state) => hasPermission(state.permissions, 'contentAdmin'),
    isManageFeaturedVideosAllowed: (state) => hasPermission(state.permissions, 'contentAdmin'),
    isManageFeaturedCarouselsAllowed: (state) => hasPermission(state.permissions, 'contentAdmin'),
    isManageUsersAllowed: (state) => hasPermission(state.permissions, 'manageUsers'),
    isManageCategoriesAllowed: (state) => hasPermission(state.permissions, 'categoriesAdmin'),
    isManageRolesAllowed: (state) => hasPermission(state.permissions, 'rolesAdmin'),
    isManageGroupsAllowed: (state) => hasPermission(state.permissions, 'groupsAdmin'),
    isManageTagsAllowed: (state) => hasPermission(state.permissions, 'tagsAdmin'),
    isOpenAdminPanelAllowed: (state) => hasPermission(state.permissions, 'accessToAdministration'),
    isManageAudioIndexingAllowed: (state) => hasPermission(state.permissions, 'runAudioIndexing'),
    isManageRetentionAllowed: (state) => hasPermission(state.permissions, 'setRetainItem'),
    isManageSettingsAllowed: (state) =>
      state.settings?.isSettingsPageEnabled && hasPermission(state.permissions, 'manageSettings'),
  },
  actions: {
    successAuthenticated(user: User$Details) {
      this.isAuth = true;
      this.user = user;
    },

    failedAuthenticated() {
      this.isAuth = false;
      this.user = null;
    },

    setApplications(applications: RavnurApplication[]) {
      this.applications = applications;
    },

    setPermissions(permissions: Permissions) {
      this.permissions = permissions;
    },

    setPortalSettings(settings: PortalSettings) {
      this.settings = settings;
    },

    setPublicDomain(domain: string) {
      this.publicDomain = `${domain}/`;
    },
  },
});

export default useSecurityStore;

export type SecurityStore = ReturnType<typeof useSecurityStore>;

function hasPermission(
  permissions: Permissions,
  key: keyof SubType<Permissions, Permissions$Boolean>
) {
  return (
    permissions[key] === Permissions$Boolean.TRUE ||
    permissions.fullAccess === Permissions$Boolean.TRUE
  );
}

function detectDefaultSite(
  user: Nullable<User$Details>,
  apps: RavnurApplication[]
): RavnurApplication | undefined {
  const predicate = user ? (a: RavnurApplication) => a.id === user.defaultSiteId : () => false;

  return find(predicate, apps) || findCurrentApplication(apps) || head(apps);
}
